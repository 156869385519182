import { render, staticRenderFns } from "./HowItWorksSlider.vue?vue&type=template&id=072d450c&scoped=true"
import script from "./HowItWorksSlider.vue?vue&type=script&lang=js"
export * from "./HowItWorksSlider.vue?vue&type=script&lang=js"
import style0 from "./HowItWorksSlider.vue?vue&type=style&index=0&id=072d450c&prod&scoped=true&lang=scss"
import style1 from "./HowItWorksSlider.vue?vue&type=style&index=1&id=072d450c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072d450c",
  null
  
)

export default component.exports