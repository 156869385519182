<template>
  <client-only>
    <section id="public-how-it-works" class="process">
      <div class="container">
        <div class="process__inner">
          <h2 class="process__title ui-h2 index-page__section-title">
            Как это работает для
            <button
              class="process__title-btn -worker"
              :class="activeTab === 'installer' ? 'js-active' : ''"
              data-tab="1"
              @click="activeTab = 'installer'"
            >
              монтажника
            </button>
            или
            <button
              class="process__title-btn -customer"
              :class="activeTab === 'client' ? 'js-active' : ''"
              data-tab="0"
              @click="activeTab = 'client'"
            >
              заказчика
            </button>
          </h2>
          <div class="process__text index-page__section-subtitle">
            <p>{{ subtitle }}</p>
          </div>
        </div>
        <div class="process__slider-wrap">
          <div v-if="activeTab === 'client'" class="process__slider-tab -customer">
            <HowItWorksSlider key="client" :slider="sliders.client" :is-active="activeTab === 'client'" />
          </div>
          <div v-if="activeTab === 'installer'" class="process__slider-tab js-active">
            <HowItWorksSlider key="installer" :slider="sliders.installer" :is-active="activeTab === 'installer'" />
          </div>
        </div>
      </div>
    </section>
  </client-only>
</template>

<script>
import HowItWorksSlider from '@/components/shared/HowItWorks/HowItWorksSlider'
import { getConfig } from '@/configs/app.configs'
import { FOREIGN_LANDINGS_COUNTRIES } from '@/constants/foreign-landings/foreign-landings.constants'
import { COMPANY } from '@/constants/global.constant'
import { useUserStore } from '@/store/modules/user.store'

export default {
  name: 'HowItWorks',
  components: { HowItWorksSlider },
  props: {
    country: {
      type: String,
      default: '',
    },
    installerAnchor: Boolean,
  },
  data() {
    return {
      subtitle: 'Три шага к решению любой проблемы с водоснабжением, канализацией и отоплением',
      activeTab: 'installer',
    }
  },
  computed: {
    isForeignLanding() {
      return this.$store.state.pages.foreignLandings.country
    },
    isForeignLandingForInstaller() {
      return this.$store.state.pages.foreignLandings.type === 'installer'
    },
    sliders() {
      const isInstaller = useUserStore().isInstaller
      return {
        client: {
          img: '/images/process-1.webp',
          slides: [
            {
              title: 'Создай заказ',
              listItems: [
                'Заполни короткую форму с описанием задачи и того, в чем могут помочь наши монтажники',
                `Мы обеспечим мониторинг и контроль твоего&nbsp;заказа со стороны ${COMPANY.name}`,
              ],
              button: {
                text: 'Создать заказ',
                color: 'red',
                action: {
                  to: {
                    path: '/order',
                  },
                  yandexMetrikaGoal: 'ClickHowItWorksClientCreateOrder',
                  hasRequiredRole: true,
                },
              },
            },
            {
              title: 'Выбери монтажника',
              listItems: [
                'Прямой доступ к&nbsp;тысячам лучших из&nbsp;лучших монтажников, чью квалификацию мы&nbsp;гарантируем',
                `Каждый монтажник проходит обучение в&nbsp;академии ${COMPANY.name} и&nbsp;сертификацию`,
              ],
              button: {
                text: 'Найти монтажника',
                color: 'green',
                action: {
                  to: {
                    name: 'search',
                    ...(this.isForeignLanding && {
                      query: {
                        country: FOREIGN_LANDINGS_COUNTRIES[this.country].fullTitle,
                      },
                    }),
                  },
                  yandexMetrikaGoal: 'ClickHowItWorksClientChooseInstaller',
                  hasRequiredRole: true,
                },
              },
            },
            {
              title: 'Реши свою проблему',
              listItems: [
                'Получи консультацию, расчет от&nbsp;монтажника, согласуй детали работ на&nbsp;объекте',
                'Мы обеспечим авторский надзор за&nbsp;работой специалиста и&nbsp;дадим гарантию на&nbsp;его монтаж',
              ],
              button: {
                text: 'Создать заказ',
                color: 'red',
                action: {
                  to: {
                    path: '/order',
                  },
                  yandexMetrikaGoal: 'ClickHowItWorksClientSolveProblem',
                  hasRequiredRole: true,
                },
              },
            },
          ],
        },

        installer: {
          img: '/images/process-2.webp',
          slides: [
            {
              title: 'Зарегистрируйся',
              listItems: [
                'Заполни короткую форму регистрации',
                'Укажи на каких работах ты специализируешься и города, в которых принимаешь заказы',
              ],
              button: {
                text: 'Зарегистрироваться',
                color: 'red',
                action: {
                  externalLink: `${getConfig('oldPublicUrl')}/montage`,
                  // yandexMetrikaGoal: 'ClickHowItWorksInstallerSolveProblem',
                  hasRequiredRole: isInstaller,
                },
              },
            },
            {
              title: 'Пройди обучение',
              listItems: [
                'Зарегистрируйся онлайн → Посети нашу академию → Сдай тест → Получи\nсертификат',
                `У&nbsp;тебя будет персональный куратор от&nbsp;${COMPANY.name}, который тебе всегда поможет`,
              ],
              button: {
                text: 'Зарегистрироваться',
                color: 'red',
                action: {
                  externalLink: `${getConfig('oldPublicUrl')}/montage`,
                  // ...(this.isForeignLandingForInstaller && {
                  //   scrollTo: '.city-find__bot-wrap',
                  // }),
                  // yandexMetrikaGoal: 'ClickHowItWorksInstallerTakeCourses',
                  hasRequiredRole: isInstaller,
                },
              },
            },
            {
              title: 'Получи заказы',
              listItems: [
                'Теперь мы уверены в тебе, ты можешь получать заказы от наших клиентов',
                `Получать кэшбэк от ${COMPANY.name} реальными деньгами, а не баллами`,
                `Участвовать в&nbsp;акциях от&nbsp;${COMPANY.name} и&nbsp;наших партнеров. Получать форму и&nbsp;инструмент в&nbsp;награду за&nbsp;результат`,
              ],
              button: {
                text: 'Зарегистрироваться',
                color: 'red',
                action: {
                  externalLink: `${getConfig('oldPublicUrl')}/montage`,
                  // yandexMetrikaGoal: 'ClickHowItWorksInstallerSolveProblem',
                  hasRequiredRole: isInstaller,
                },
              },
            },
          ],
        },
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/index/_process.scss';
</style>
