var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process__slider-tab-inner"},[_c('div',{staticClass:"process__slider-tab-col -img"},[_c('div',{staticClass:"process__slider-img-wrap"},[_c('img',{staticClass:"process__slider-img",attrs:{"src":_vm.slider.img,"loading":"lazy","alt":"Как это работает?","height":"690","width":"584"}})])]),_vm._v(" "),_c('div',{staticClass:"process__slider-tab-col -slider"},[_c('div',{staticClass:"process__slider-wrap"},[_c('swiper',{ref:"processCustomerSwiper",staticClass:"process__slider swiper-container swiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.slider.slides),function(slide,index){return _c('swiper-slide',{key:slide.title,staticClass:"process__slide process-slide swiper-slide"},[_c('div',{staticClass:"process-slide__step"},[_vm._v("Шаг "+_vm._s(index + 1)+" из "+_vm._s(_vm.slider.slides.length))]),_vm._v(" "),_c('h3',{staticClass:"process-slide__title"},[_vm._v(_vm._s(slide.title))]),_vm._v(" "),_c('ul',{staticClass:"process-slide__list"},_vm._l((slide.listItems),function(item){return _c('li',{key:item,staticClass:"process-slide__item",domProps:{"innerHTML":_vm._s(item)}})}),0),_vm._v(" "),(slide.button.action.to)?_c('router-link',{attrs:{"to":slide.button.action.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{class:[
                'process-slide__cta-btn',
                'ui-btn',
                slide.button.color === 'red' ? '-cta' : '',
                slide.button.color === 'green' ? '-cta-green' : '',
              ],attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.handleSliderLinkClick(slide.button.action)}}},[_c('span',{staticClass:"ui-btn__text"},[_vm._v(_vm._s(slide.button.text))]),_vm._v(" "),_c('span',{staticClass:"ui-btn__icon"},[_c('svg',{attrs:{"width":"11","height":"18","viewBox":"0 0 11 18","fill":"white","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M7.2269 8.99998L0 1.69811L1.68067 0L10.5882 8.99998L1.68067 18L0 16.3019L7.2269 8.99998Z"}})])])])]}}],null,true)}):_vm._e(),_vm._v(" "),(slide.button.action.externalLink)?_c('a',{class:[
              'process-slide__cta-btn',
              'ui-btn',
              slide.button.color === 'red' ? '-cta' : '',
              slide.button.color === 'green' ? '-cta-green' : '',
            ],attrs:{"href":slide.button.action.externalLink},on:{"click":function($event){$event.preventDefault();return _vm.handleSliderLinkClick(slide.button.action)}}},[_c('span',{staticClass:"ui-btn__text"},[_vm._v(_vm._s(slide.button.text))]),_vm._v(" "),_c('span',{staticClass:"ui-btn__icon"},[_c('svg',{attrs:{"width":"11","height":"18","viewBox":"0 0 11 18","fill":"white","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M7.2269 8.99998L0 1.69811L1.68067 0L10.5882 8.99998L1.68067 18L0 16.3019L7.2269 8.99998Z"}})])])]):_vm._e()],1)}),1),_vm._v(" "),_c('div',{staticClass:"index-page__slider-arrows"},[_c('div',{staticClass:"swiper-button-prev"},[_c('svg',{attrs:{"width":"20","height":"16","viewBox":"0 0 20 16","fill":"black","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.59844 0.679687L0.398438 7.87969L7.59844 15.0797L9.29644 15.0797L2.69644 8.47969L19.5984 8.47969V7.27969L2.69644 7.27969L9.29644 0.679687L7.59844 0.679687Z"}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",attrs:{"data-note":"0"}},[_c('div',{staticClass:"circle"},[_c('svg',{staticClass:"circle__svg",attrs:{"width":"36","height":"36"}},[_c('circle',{staticClass:"circle__progress circle__progress--path",attrs:{"cx":"18","cy":"18","r":"16"}}),_vm._v(" "),_c('circle',{staticClass:"circle__progress circle__progress--fill",attrs:{"cx":"18","cy":"18","r":"16"}})])]),_vm._v(" "),_c('svg',{staticClass:"swiper-button-next__svg",attrs:{"width":"20","height":"16","viewBox":"0 0 20 16","fill":"black","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.3977 15.0801L19.5977 7.88008L12.3977 0.680077L10.6997 0.680077L17.2997 7.28008L0.397658 7.28008L0.397658 8.48008L17.2997 8.48008L10.6997 15.0801L12.3977 15.0801Z"}})])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }