<template>
  <Modal
    v-if="showModal"
    class="pd-modal"
    :fullscreen-on-mobile="false"
    :show-close-button="true"
    :modal-name-in-store="modalNameInStore"
  >
    <div class="pd-modal-inner">
      <div class="pd-modal-title">Согласие на обработку персональных данных</div>
      <div class="pd-modal-fulltext">
        <span class="pd-modal-bolder">Я подтверждаю и признаю, что я прочитал(-а) </span>
        <nuxt-link to="/personal-data-policy.pdf" target="_blank" custom
          >Политику в отношении обработки персональных данных</nuxt-link
        >
        в информационно-коммуникационной сети Интернет и
        <nuxt-link to="/agreement.pdf" target="_blank" custom>Пользовательское соглашение</nuxt-link>
        и даю свое безусловное согласие ООО «{{ COMPANY.name }}» (адрес: Россия, 117186, г. Москва, ул. Нагорная, дом
        3А) на обработку моих персональных данных. Мое согласие действует в отношении персональных данных, которые
        внесены мною в соответствующие поля веб-формы. Я подтверждаю, что внесенные персональные данные принадлежат мне,
        являются актуальными и не содержат ошибок. Я также подтверждаю, что не вносил в веб-форму персональные данные,
        которые прямо не запрашивались в соответствующих полях, а также персональные данные, касающиеся расовой,
        национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья,
        интимной жизни и иные специальные категории персональных данных.

        <div class="pd-modal-gap"></div>
        <span class="pd-modal-bolder"
          >Я даю согласие на использование ООО «{{ COMPANY.name }}» моих персональных данных в следующих целях:</span
        ><br />

        &mdash; направление мне справочной и маркетинговой информации, включая рассылки рекламного характера, путем
        осуществления прямых контактов или в виде смс-оповещения по моему номеру телефона, а также посредством сообщения
        на мой адрес электронной почты<br />
        &mdash; предоставление мне возможности для обратной связи с ООО «{{ COMPANY.name }}»<br />
        &mdash; предоставление мне консультаций по вопросам, касающимся продукции и оказываемых услуг<br />
        &mdash; содействие в обмене опытом и организация коммуникации между пользователями Интернет-сайтов<br />
        &mdash; маркетинговая коммуникация и поддержка<br />
        <div class="pd-modal-gap"></div>
        <span class="pd-modal-bolder"
          >Я согласен на осуществление следующих действий в отношении моих персональных данных:</span
        >
        cбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
        уничтожение персональных данных. Я согласен на трансграничную передачу моих персональных данных. В отношении
        моих персональных данных могут применяться автоматизированная обработка и обработка без использования средств
        автоматизации. Персональные данные могут обрабатываться на материальных носителях и в информационных системах
        персональных данных.
        <div class="pd-modal-gap"></div>
        <span class="pd-modal-bolder">
          Я даю ООО «{{ COMPANY.name }}» согласие на передачу моих персональных данных
        </span>
        третьим лицам: «{{ COMPANY.name }} AG + Co» (ул. Отто-Хан-Штрассе 2, 95111 {{ COMPANY.name }}, Германия); ООО
        «Мастерхост» (123317, г. Москва, Пресненская набережная, дом 12, этаж 31), ООО «Сеть дата-центров «Селектел»
        (Цветочная ул., д. 19, Санкт-Петербург, 196084), а также иным лицам в случаях, предусмотренных действующим
        законодательством.
        <div class="pd-modal-gap"></div>
        <span class="pd-modal-bolder"
          >Согласие действует не дольше, чем необходимо для достижения целей обработки персональных данных.</span
        >
        Согласие может быть отозвано в любой момент путем направления сообщения на электронную почту
        contact-rus@rehau.com с электронного адреса, указанного в веб-форме при регистрации.
        <br />
        <UIButton
          ref="registrationButton"
          :breakpoints="['0', '1021', '1457']"
          :sizes="['11.25vw', '2.63543vw', '39px']"
          text="Закрыть"
          theme="pink"
          :disabled="false"
          @click.native="closeModal"
          >Закрыть</UIButton
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import UIButton from '@/components/ui/UIButton'
import { COMPANY } from '@/constants/global.constant'
/* import { appConfig } from '@/utils/config-api'; */
export default {
  components: { UIButton, Modal },
  data() {
    return {
      COMPANY,
      clientsPortalURL: '', // appConfig.clientsPortalURL,
      modalNameInStore: 'personalDataConsent',
    }
  },
  computed: {
    showModal() {
      return this.$store.state.modules.modals.modals[this.modalNameInStore]
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('modules/modals/updateModal', {
        name: this.modalNameInStore,
        status: false,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/shared/personal-data-consent-modal.scss';
</style>

<style lang="scss">
.pd-modal .modal__close {
  z-index: 9999;
}
</style>
