// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icons/auth/modal_xcross.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pd-modal[data-v-955a9886]{align-items:center;display:flex;flex-direction:row;justify-content:center;line-height:1.1;z-index:1400}.pd-modal[data-v-955a9886],.pd-modal-darker[data-v-955a9886]{bottom:0;left:0;position:fixed;right:0;top:0}.pd-modal-darker[data-v-955a9886]{background:#818181;z-index:1440}.pd-modal-inner[data-v-955a9886]{background:#fff;max-height:90%;overflow-y:auto;padding:4.6875vw;position:relative;width:90.625vw;z-index:1500}.pd-modal-close[data-v-955a9886]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:5.625vw;cursor:pointer;height:12.5vw;position:absolute;right:0;top:0;width:12.5vw}.pd-modal-title[data-v-955a9886]{font-size:5.625vw;font-weight:600;padding-bottom:3.125vw}.pd-modal-fulltext[data-v-955a9886]{font-size:3.75vw}.pd-modal-gap[data-v-955a9886]{height:2.8125vw;width:100%}.pd-modal-bolder[data-v-955a9886]{font-weight:600}.pd-modal button[data-v-955a9886]{margin-top:3.125vw}.pd-modal a[data-v-955a9886]{color:#e50040;-webkit-text-decoration:none;text-decoration:none}@media(min-width:1021px){.pd-modal-inner[data-v-955a9886]{padding:2.92826vw;width:60.1757vw}.pd-modal-close[data-v-955a9886]{background-size:1.31772vw;height:2.92826vw;width:2.92826vw}.pd-modal-title[data-v-955a9886]{font-size:1.75695vw;padding-bottom:2.04978vw}.pd-modal-fulltext[data-v-955a9886],.pd-modal-gap[data-v-955a9886]{font-size:1.24451vw;line-height:1.2}.pd-modal-gap[data-v-955a9886]{height:.65886vw}}@media(min-width:1457px){.pd-modal-inner[data-v-955a9886]{padding:43px;width:880px}.pd-modal-close[data-v-955a9886]{background-size:19px;height:43px;width:43px}.pd-modal-title[data-v-955a9886]{font-size:26px;padding-bottom:30px}.pd-modal-fulltext[data-v-955a9886]{font-size:18px;line-height:1.2}.pd-modal-gap[data-v-955a9886]{height:10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
